import Vue from "vue";
import Vuex from "vuex";

import articles from "./articles";
import authors from "./authors";
import global from "./global";
import records from "./records";
import subvoce from "./subvoce";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        articles,
        authors,
        global,
        records,
        subvoce
    }
});
