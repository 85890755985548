<template>
<div id="app">
    <Header />
    <Map />
    <FilterPanel />
    <Intro v-if="!introDismissed"/>

    <SidePanel><router-view /></SidePanel>
</div>
</template>

<script>
import FilterPanel from '@/components/FilterPanel'
import Header from '@/components/Header'
import Map from '@/components/Map'
import SidePanel from '@/components/SidePanel'
import Intro from '@/components/Intro'
import { mapGetters } from "vuex"

export default {
    name: 'App',
    components: {
        FilterPanel,
        Header,
        Map,
        SidePanel,
        Intro
    },
    computed: {
        ...mapGetters({
            'introDismissed': 'global/introDismissed'
        }),
    },
    mounted() {
        this.$store.dispatch('records/fetch_list')
        this.$store.dispatch('articles/fetch_list')
    }
}
</script>

<style lang="scss" scoped>
</style>
