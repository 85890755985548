<template>
    <figure>
        <img :src="block.value.image_url">
        <figcaption>{{ block.value.image_caption }}</figcaption>
    </figure>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
    name: 'ImageBlock',
    components: {
        LoadingIndicator
    },
    props: {
        block: Object
    }
}
</script>

<style lang="scss" scoped>
figure {
    margin: 0;
    padding: 0;

    img {
        max-width: 100%;
    }
}
</style>
