<template>
<div id="loading">
    <div class="spinner">
        <LoadingIndicator />
    </div>
</div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
    name: 'Loading',
    components: {
        LoadingIndicator
    }
}
</script>

<style lang="scss" scoped>
.loading {
    background-color: #333;
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
}
.spinner {
    position: absolute;
    left: calc(50% - 25px);
    transform: translateY(-50%);
    top: 50%;
}
</style>
