<template>
<div class="home"></div>
</template>

<script>
import Loading from "@/components/Loading"
import StreamField from "@/components/StreamField"

export default {
    name: 'Home',
    components: {
        Loading,
        StreamField
    },
    head: {
        title: {
            inner: 'Homepage'
        },
        meta: [
            {name: 'description', content: 'Digital Augustan Rome is a long term mapping project that is prepared to provide a worthy digital successor to the published book and maps of Mapping Augustan Rome that appeared as Supplement 50 in the Journal of Roman Archaeology Series, 2002.', id: 'seo-desc'},
            {name: 'og:description', content: 'Digital Augustan Rome is a long term mapping project that is prepared to provide a worthy digital successor to the published book and maps of Mapping Augustan Rome that appeared as Supplement 50 in the Journal of Roman Archaeology Series, 2002.', id: 'og-desc'},
        ]
    },
    data: function() {
        return {
            introduction: null,
            loading: false,
        }
    },
    methods: {
    },
    created() {
        this.loading = true
        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/pages/?slug=introduction&type=generic.TextPage&fields=body,authors(*)`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                this.introduction = req.response.items[0]
            } else{
                console.log(`Search error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            console.log(`introduction request failed.`)
        }
        this.loading = false
    },
}
</script>
