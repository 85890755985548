<template>
    <div>
        <div ref="map" id="map" :class="classes"></div>
        <div class="ctl-wrapper ctl-zoom">
            <button class="btn btn-fill ctl-btn btn-zoom-out" :disabled="!canZoomOut" @click="onZoomOut">
                <IconMinus />
            </button>
            <button class="btn btn-fill ctl-btn btn-zoom-in" :disabled="!canZoomIn" @click="onZoomIn">
                <IconPlus />
            </button>
        </div>
        <div class="ctl-wrapper ctl-map">
            <button class="btn ctl-btn btn-map preview-dag" :class="{ 'btn-map-selected': layerSelected == 'dag' }"
                @click="onChangeLayer('dag')"></button>
            <button class="btn ctl-btn btn-map preview-satelite" :class="{ 'btn-map-selected': layerSelected == 'satelite' }"
                @click="onChangeLayer('satelite')"></button>
            <button class="btn ctl-btn btn-map preview-road" :class="{ 'btn-map-selected': layerSelected == 'street' }"
                @click="onChangeLayer('street')"></button>
        </div>
        <div class="ctl-wrapper ctl-filter">
            <button class="btn btn-fill ctl-btn btn-filter" @click="onFiltersOpen">Filter</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import L from "leaflet"

import IconMinus from "@/assets/svg/icon-minus.svg"
import IconPlus from "@/assets/svg/icon-plus.svg"
import Marker from "@/assets/svg/marker-point.svg"

export default {
    name: "SimpleMap",
    components: {
        IconMinus,
        IconPlus
    },
    data() {
        return {
            boundaryTL: L.latLng(41.910465, 12.461607),
            boundaryBR: L.latLng(41.875519, 12.514307),
            center: [41.89382444498393, 12.480154860363276],
            map: null,
            mapPreview: null,
            markers: {},
            selectedMarker: null,
            previousMarker: null,
            markerLayerGroup: null,
            layerCurrent: null,
            layerDefault: 'dag',
            layerSelected: null,
            layers: {
                dag: L.tileLayer('https://aml.azureedge.net/maps/digital-augustan-rome/{z}/{x}/{y}.png', {
                    attribution: "&copy; Parrhasian Heritage Foundation",
                    minZoom: 12,
                    maxZoom: 17,
                    tms: false
                }),
                satelite: L.tileLayer.bing({
                    bingMapsKey: 'AsFIGbbKpl9GxAWGOy-exfTGv4e67_R7ynDgeIBo3E4dv--ZypMVpuVsXn181yxg',
                    imagerySet: 'Aerial'
                }),
                street: L.tileLayer.bing({
                    bingMapsKey: 'AsFIGbbKpl9GxAWGOy-exfTGv4e67_R7ynDgeIBo3E4dv--ZypMVpuVsXn181yxg',
                    imagerySet: 'Road'
                })
            },
            markerPoint: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 10.82"><path d="M945.57,517c9.48,0,10,10.82,10,10.82s.6-10.82,10.87-10.82Z" transform="translate(-945.46 -517)"/></svg>'
        };
    },
    computed: {
        ...mapGetters({
            'record': 'records/record',
            'records': 'records/records',
            'expanded': 'global/sidepanelExpanded',
            'open': 'global/sidepanelOpen'
        }),
        canZoomIn() {
            return this.map ? this.map.getZoom() != this.map.getMaxZoom() : false
        },
        canZoomOut() {
            return this.map ? this.map.getZoom() != this.map.getMinZoom() : false
        },
        classes() {
            return [
                'map',
                this.width,
                'leaflet-container leaflet-retina leaflet-fade-anim leaflet-grab leaflet-touch-drag'
            ]
        },
        width() {
            if(!this.open) {
                return 'panel-closed'
            } else if (this.open && !this.expanded) {
                return 'panel-open'
            } else {
                return 'panel-open-expanded'
            }
        }
    },
    methods: {
        /**
         * Add points to the Map, triggered on mounted, or watch:records
         */
        addPoints() {
            if(this.markerLayerGroup) {
                this.markerLayerGroup.clearLayers();
            }

            this.markerLayerGroup = L.layerGroup().addTo(this.map)
            this.records.forEach(record => {
                const icon = L.divIcon({
                    className: 'marker',
                    html: `<div class="marker-pin"><div class="marker-pin-id">${record.identifier}</div>${this.markerPoint}</div>`,
                    iconSize: [34, 32],
                    iconAnchor: [17, 32]
                });
                const m = L.marker([record.latitude, record.longitude],
                    {
                        title: record.title,
                        icon: icon
                    })
                .addTo(this.markerLayerGroup)
                .on('click', this.onMarkerClick)

                m._icon.id = record.slug

                this.markers[record.slug] = m
            })

            // Check if a record is loaded and navigate to it
            this.onRecordChange()
        },

        /**
         * Helper to convert coordinates into a Leaflet `L.latLng` point,
         */
        getPoint(lat, long) {
            return lat && long ? L.latLng(lat, long) : L.latLng(0,0)
        },

        /**
         *
         */
        onLayerChanged(id) {
            if(!id) {
                return
            }

            if(id in this.layers) {
                this.layerSelected = id
                if(this.layerCurrent) {
                    try {
                        this.map.removeLayer(this.layerCurrent)
                    }
                    catch (err) {
                        console.error(err)
                    }
                }
                this.layerCurrent = this.layers[id]
                this.map.addLayer(this.layerCurrent)
            } else {
                console.error(`Error: Cannot load map, layer with id ${id} not found`)
            }
        },

        /**
         * Open the filter panel
         */
        onFiltersOpen() {
            this.$store.dispatch('global/filtersToggle', true)
        },

        /**
         * Handle Marker Click
         */
        onMarkerClick(e) {
            this.$router.push({name: 'record', params:{slug: e.target._icon.id}}, () => {})
            this.updateMarkerState(e.target._icon)
        },

        updateMarkerState(newSelectedMarker) {
            this.previousMarker = this.previousMarker ? this.selectedMarker : newSelectedMarker;
            this.selectedMarker = newSelectedMarker;

            if (this.previousMarker != null) {
                this.previousMarker.classList.remove('selected-marker');
            }

            this.selectedMarker.classList.add('selected-marker');
        },

        /**
         * Handle when the selected record changes
         */
        onRecordChange() {
            if(this.record) {
                if(this.record.latitude && this.record.longitude) {
                    this.map.flyTo(this.markers[this.record.slug].getLatLng(), 17)
                }

                let marker = document.querySelector(`#${this.record.slug}`)
                this.updateMarkerState(marker);
            }
        },

        /**
         * When the viewport resizes, Leaflet needs to have it's size
         * invalidated so that it can internally update it's dims.
         */
        onResize() {
            let m = this.map
            if(this.map) {
                setTimeout(function(){
                    m.invalidateSize()
                }, 400);
            }
        },

        /**
         * Change the map to use the provided layer: `dag`, `satelite` or `road`
         */
        onChangeLayer(layer) {
            this.$router.push({
                path: window.location.pathname,
                hash: this.$stateHash.set('map', layer)
            }, () => {})
        },

        /**
         * Handle on map zoom in
         */
        onZoomIn() {
            this.map.setZoom(this.map.getZoom() + 1)
        },

        /**
         * Handle on map zoom out
         */
        onZoomOut() {
            this.map.setZoom(this.map.getZoom() - 1)
        }
    },
    mounted() {
        const select = this.$stateHash.get('map')

        // Patch for broken icons
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });

        this.map = L.map('map', {
            center: this.center,
            //maxBounds: L.latLngBounds(this.boundaryTL, this.boundaryBR),
            minZoom: 15,
            maxZoom: 17,
            zoom: 16,
            zoomControl: false
        })

        this.onLayerChanged(select || this.layerDefault)

        if(this.records) {
            this.addPoints()
        }
    },
    watch: {
        '$route.hash': function(hash) {
            this.onLayerChanged(this.$stateHash.get('map'))
        },
        expanded() {
            this.onResize()
        },
        open() {
            this.onResize()
        },
        record() {
            this.onRecordChange()
        },
        records() {
            this.addPoints()
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/styles/_vars.scss";

.map {
    height: $height-body;
    left: 0;
    position: absolute;
    top: $height-header;
    transition: $transition-default;
    z-index: 0;
    &.leaflet-container {
        background-color: #000;
    }

    &.panel-closed {
        width: 100%;
    }
    &.panel-open {
        width: $width-map;
    }
    &.panel-open-expanded {
        width: $width-map-expanded;
    }
}

@media only screen and (max-width: $mq-max-width-mobile) {
    .map {
        width: 100%;
        &.panel-open {
            height: $map-mobile-height-body;
            width: 100%;
        }
        &.panel-open-expanded {
            height: $map-mobile-height-body;
            width: 100%;
        }
    }
}


.ctl-wrapper {
    background: linear-gradient(180deg, rgba(82,101,111,0.3) 0%, rgba(50,62,69,0.3) 100%);
    border-radius: 3px;
    box-shadow: $glow-ui-outter;
    position: fixed;
}

.ctl-btn {
    background: linear-gradient(180deg, rgba(176,187,193,.8) 0%, rgba(140,157,166,.8) 100%);
    display: block;
    float: left;
    margin: 6px;
    outline: none;
    transition: $transition-default;
    height: 30px;
    width: 30px;

    &:focus {
        outline: $outline;
    }
}

.btn-filter, .btn-map {
    font-variant: small-caps;
    text-transform: lowercase;
    width: 67px;
}

.btn-map {
    background-size: 128px 128px;
    height: 67px;

    &:hover {
        background-size: 128px 128px;
    }

    &.preview-dag {
        background-image: url('../assets/img/map-preview-dag.jpg');
    }
    &.preview-road {
        background-image: url('https://dev.virtualearth.net/REST/v1/Imagery/Map/Road/41.89382444498393,12.480154860363276/15?mapSize=256,256&key=AoIgDv0ESZhfrOBF35qizIRpfBl9WjHqlcKMLLsqq7updYew-Tl9q2ZxjixyM6aC');
    }
    &.preview-satelite {
        background-image: url('https://dev.virtualearth.net/REST/v1/Imagery/Map/Aerial/41.89382444498393,12.480154860363276/15?mapSize=256,256&key=AoIgDv0ESZhfrOBF35qizIRpfBl9WjHqlcKMLLsqq7updYew-Tl9q2ZxjixyM6aC');
    }
}

.btn-zoom-in {
    margin-left: 3px;
    svg {
        width: 10px;
    }
}

.btn-zoom-out {
    line-height: 0;
    margin-right: 3px;
    svg {
        width: 7px;
    }
}

@supports (-webkit-touch-callout: none) {
    .btn-zoom-in {
        svg {
            margin-left: -5px;
        }
    }

    .btn-zoom-out {
        svg {
            margin-left: -4px;
        }
    }
}

.ctl-filter {
    left: 15px;
    top: $height-header + 62px + 85px;
}

.ctl-map {
    left: 15px;
    top: $height-header + 62px;

    .btn-map {
        display: none;
    }
    .btn-map-selected {
        display: block;
    }

    &:hover {
        .btn-map {
            display: block;
        }
    }
}

.ctl-zoom {
    left: 15px;
    top: $height-header + 15px;
}

.marker-pin {
    height: 100%;
    width: 100%;

    .marker-pin-id {
        background-color: $color-blue-darker;
        border-radius: 3px;
        color: $color-white;
        display: block;
        height: 18px;
        padding: 2px;
        text-align: center;
        width: 30px;
        box-shadow: 0 0 5px rgba(14, 33, 48, 0.6);
    }

    svg {
        display: block;
        fill: $color-blue-darker;
        left: 7px;
        position: relative;
        width: 20px
    }
}

.marker {
    &:hover {
        z-index: 1000 !important;

        .marker-pin {
            transform: scale(1.2);
            transition: transform 200ms ease-out;
            transform-origin: bottom center;
        }
    }

    &.selected-marker {
        .marker-pin {
            transform: scale(1.2);
            transition: transform 200ms ease-out;

            .marker-pin-id {
                background-color: $color-white;
                border-radius: 3px;
                color: $color-blue-darker;
                display: block;
            }
            svg {
                fill: $color-white;
            }
        }
    }
}
</style>

