<template>
    <div>
        <h3>{{ block.value.heading }}</h3>
        <h4>{{ block.value.sub_heading }}</h4>
    </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
    name: 'HeadingBlock',
    components: {
        LoadingIndicator
    },
    props: {
        block: Object
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 0;
}
</style>
