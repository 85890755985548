<template>
<table>
    <thead>
        <tr>
            <th v-for="head in headings" :key="head">{{ head }}</th>
        </tr>
    </thead>
    <tbody>
        <slot></slot>
    </tbody>
</table>
</template>

<script>
export default {
    name: 'TableSet',
    props: {
        headings: Array
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/_vars.scss";

table {
    border-spacing: 0;
    border-top: 1px $color-gray solid;
    width: 100%;

    tbody {
        border-top: 1px $color-gray solid;
    }

    tr {
        td a, th {
            border-collapse: collapse;
            padding: 0.5rem 0.5rem;
            text-align: left;
        }
        th {
            border-bottom: 1px $color-gray solid;
        }
        td a {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
        }

        &:nth-child(even) {
            background-color: rgba(0,0,0,0.05);
        }

        td:first-child {
            width: 15%;
            max-width: 80px;   
        }

        td:first-child, th:first-child {
            border-right: 1px $color-gray solid;
        }
    }
    tbody {
        tr:hover {
            background-color: rgba(0,0,0,0.1);
        }
    }
}
</style>
