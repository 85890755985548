<template>
<div class="spinner-border" role="status" aria-hidden="true"></div>
</template>

<script>
export default {
    name: 'LoadingIndicator'
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

$spinner-border-width: 4px;
$spinner-height: 50px;
$spinner-width: 50px;

@keyframes spinner-border {
    to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: $spinner-width;
    height: $spinner-height;
    vertical-align: text-bottom;
    border: $spinner-border-width solid $color-blue;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 2s linear infinite;
}
</style>
