<template>
<div>
    <Loading v-if="loading" />
    <div v-else>
        <div class="wrapper-title">
            <h1 v-if="route == 'records'">Records with Locations</h1>
            <h1 v-else>Records without Locations</h1>
            <SearchBar>
                <input class="searchbox" placeholder="Search" type="text"
                    v-model="query" />
                <button class="search-clear" v-if="query" @click="onClearQuery"><IconX /></button>
            </SearchBar>
        </div>
        <TableSet :headings="['Entry', 'Name']">
            <tr v-for="record in records" :key="record.slug">
                <td>
                    <router-link :to="{name:'record', params:{slug: record.slug}}"
                            @click="onRecordClick(record.slug)">
                        {{ record.identifier != 0 ? record.identifier : '&ndash;' }}
                    </router-link>
                </td>
                <td>
                    <router-link :to="{name:'record', params:{slug: record.slug}}"
                            @click="onRecordClick(record.slug)">
                        {{ record.title }}
                    </router-link>
                </td>
            </tr>
        </TableSet>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

import Loading from "@/components/Loading"
import SearchBar from "@/components/SearchBar"
import TableSet from "@/components/TableSet"
import IconX from "@/assets/svg/icon-x.svg"

export default {
    name: 'Directory',
    components: {
        IconX,
        Loading,
        SearchBar,
        TableSet
    },
    data: function() {
        return {
            route: null,
            query: null
        }
    },
    computed: {
        ...mapGetters({
            'loading': 'records/records_loading',
            'records': 'records/records'
        })
    },
    head: {
        title: {
            inner: 'Directory'
        },
        meta: [
            
            {name: 'description', content: '', id: 'seo-desc'},
            {name: 'og:description', content: '', id: 'og-desc'},
            {name: 'og:title', content: 'Directory', id: 'og-title'}
        ]
    },
    methods: {
        onClearQuery() {
            this.query = ''
        },
        onRecordSetChange() {
            this.$store.dispatch('records/change_record_set', this.$route.name)
            this.route = this.$route.name
        },
        onRecordClick(slug) {
            this.$store.dispatch('panel/open', true)
            this.$store.dispatch('records/select', slug)
        },
        onSearchChange() {
            this.$store.dispatch('records/search', this.query)
        }
    },
    mounted() {
        this.onRecordSetChange()
    },
    watch: {
        '$route.params': function() {
            this.onRecordSetChange()
        },
        query() {
            this.onSearchChange()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
</style>
