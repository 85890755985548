<template>
<div class="wrapper-search">
    <div class="searchbar">
        <slot></slot>
    </div>
    <router-link :to="{name:'records'}" class="recordset-toggle"><IconLocations /></router-link>
    <router-link :to="{name:'records_sans_locations'}" class="recordset-toggle"><IconLocationsNone /></router-link>
    <router-link :to="{name:'subvoce'}" class="recordset-toggle"><IconSubVoce /></router-link>
</div>
</template>

<script>
import IconLocations from "@/assets/svg/icon-location.svg"
import IconLocationsNone from "@/assets/svg/icon-location-none.svg"
import IconSubVoce from "@/assets/svg/icon-subvoce.svg"

export default {
    name: 'SearchBar',
    components: {
        IconLocations,
        IconLocationsNone,
        IconSubVoce
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

.wrapper-search {
    margin: 1rem 0;
    overflow: auto;
}

.recordset-toggle {
    background: $gradient-button;
    box-shadow: $glow-ui-inner;
    display: block;
    float: left;
    height: 30px;
    margin-left: 10px;
    overflow: hidden;
    width: 30px;

    svg {
        fill: $color-blue;
        height: 18px;
        padding: 6px;
        width: 18px;
    }

    &.active {
        svg {
            fill: $color-blue-darker;
        }
    }
}

.searchbar {
    position: relative;
    width: calc(100% - 128px);

    .search-clear {
        background: $gradient-button;
        border: 0;
        border-radius: 50%;
        height: 22px;
        line-height: 0;
        padding: 0;
        position: absolute;
        right: 0px;
        top: 4px;
        width: 22px;

        svg {
            fill: $color-blue;
            padding: 0;
            width: 8px;
        }
    }
}

.searchbox {
    background-color: #E0E0E0;
    float: left;
    height: 22px;
    padding: 4px;
    width: 100%;
}

@media only screen and (min-width: $mq-max-width-mobile) and (max-width: $mq-max-width-tablet) {

    .searchbar {
        width: calc(100% - 10px);
    }

    .searchbox {
        float: none;
    }

    .recordset-toggle {
        margin-top: .7rem;
        &:first-of-type {
            margin-left: 0;
        } 
    }
}
</style>
