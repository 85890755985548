import Vue from 'vue'
import VueRouter from 'vue-router'

import Article from '../views/Article.vue'
import Articles from '../views/Articles.vue'
import Author from '../views/Author.vue'
import Credits from '../views/Credits.vue'
import Directory from '../views/Directory.vue'
import Home from '../views/Home.vue'
import Record from '../views/Record.vue'
import SubVoce from '../views/SubVoce.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/articles/',
        name: 'articles',
        component: Articles
    },
    {
        path: '/articles/:slug',
        name: 'article',
        component: Article,
        meta: { scrollToTop: true }
    },
    {
        path: '/authors/',
        name: 'author',
        component: Author
    },
    {
        path: '/credits/',
        name: 'credits',
        component: Credits
    },
    {
        path: '/records/',
        name: 'records',
        component: Directory
    },
    {
        path: '/records-sans-locations/',
        name: 'records_sans_locations',
        component: Directory
    },
    {
        path: '/records/:slug',
        name: 'record',
        component: Record,
        meta: { scrollToTop: true }
    },
    {
        path: '/subvoce/',
        name: 'subvoce',
        component: SubVoce
    },
]

const scrollPositions = {};
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior (to, from, savedPosition) {
        let sidePanel = document.querySelector('.sidepanel');

        if (to.hash) {
            return {selector: to.hash}
        } else {   
            if (savedPosition && sidePanel !== null && to.name in scrollPositions) {
                sidePanel.scrollTop = scrollPositions[to.name];
            } else {
                sidePanel.scrollTop = 0;
            }
        }
    }
})


router.beforeEach((to, from, next) => {
    let sidePanel = document.querySelector('.sidepanel');
    if (sidePanel !== null) {
        scrollPositions[from.name] = sidePanel.scrollTop;
    }

    next();
});

export default router
