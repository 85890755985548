import Vue from 'vue'

export const state = () => ({
    authors: null,
    error: null,
    loading: false
})

export const getters = {
    authors: state => state.authors,
    error: state => state.error,
    loading: state => state.loading
};

export const actions = {
    /**
     * Retrieve a list of all Authors from the Digital Augustan Rome authors
     * list.
     * @param {*} param0
     * @param {*} slug
     */
    fetch({commit, state}) {
        if(state.authors) {
            return
        }

        commit('setLoading', true)

        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/meta/persons/?groups__name=Digital%20Augustan%20Rome%20Authors`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status == 200) {
                commit('setAuthors', req.response)
            } else{
                commit('setError', `Article error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setError', `Article request failed.`)
        }
    },
};

export const mutations = {
    setAuthors(state, response) {
        state.authors = response
        state.loading = false
    },
    setError(state, e) {
        state.error = e
        throw new Error(`Error fetching record: ${e}`)
    },
    setLoading(state, s) {
        state.loading = s
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
