export const state = () => ({
  filtersOpen: false,
  sidepanelExpanded: false,
  sidepanelOpen: false,
  introDismissed: false,
});

export const getters = {
  filtersOpen: (state) => state.filtersOpen,
  sidepanelExpanded: (state) => state.sidepanelExpanded,
  sidepanelOpen: (state) => state.sidepanelOpen,
  introDismissed: (state) => state.introDismissed,
};

export const actions = {
  filtersToggle({ commit, state }) {
    commit("setFiltersOpenValue", !state.filtersOpen);
  },
  setFiltersOpen({ commit, state }, value) {
    commit("setFiltersOpenValue", value);
  },
  setSidepanelExpanded({ commit, state }, value) {
    commit("setSidepanelExpandedValue", value);
  },
  setSidepanelOpen({ commit, state }, value) {
    commit("setSidepanelOpenValue", value);
  },
  dismissIntro({ commit, state }, value) {
    commit("setIntroDismissedValue", value);
  },
};

export const mutations = {
  setFiltersOpenValue(state, value) {
    state.filtersOpen = value;
  },
  setSidepanelExpandedValue(state, value) {
    state.sidepanelExpanded = value;
  },
  setSidepanelOpenValue(state, value) {
    state.sidepanelOpen = value;
  },
  setIntroDismissedValue(state, value) {
    state.introDismissed = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
