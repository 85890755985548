export const state = () => ({
    subvoce: null,
    subvoce_src: null,
    error: null,
    loading: false
})

export const getters = {
    subvoce: state => state.subvoce,
    error: state => state.error,
    loading: state => state.loading
};

export const actions = {
    /**
     * Retrieve a list of all sub voce from the Digital Augustan Rome API.
     * @param {*} param0
     * @param {*} slug
     */
    fetch({commit, state}) {
        if(state.subvoce) {
            return
        }

        commit('setLoading', true)

        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/dag/subvoce/`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status == 200) {
                commit('setSubVoce', req.response)
            } else{
                commit('setError', `Article error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setError', `Article request failed.`)
        }
    },

    /**
     * Search the currently loaded records set.
     * @param {*} param0
     * @param {*} q
     */
    search({commit, state}, q) {
        if(q === "") {
            commit('setFiltered', state.subvoce_src)
            return
        }

        const rx = new RegExp(q, "i")
        let results = state.subvoce_src.filter(e => {
            return e.name.search(rx) >= 0
        })
        commit('setFiltered', results)
    }
};

export const mutations = {
    setSubVoce(state, response) {
        state.subvoce = response
        state.subvoce_src = response
        state.loading = false
    },
    setError(state, e) {
        state.error = e
        throw new Error(`Error fetching record: ${e}`)
    },
    setFiltered(state, results) {
        state.subvoce = results
    },
    setLoading(state, s) {
        state.loading = s
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
