<template>
<div>
    <Loading v-if="loading" />
    <div v-else>
        <div v-if="subvoce">
            <div class="wrapper-title border-bottom">
                <h1>Sub Voce</h1>
                <SearchBar>
                    <input class="searchbox" placeholder="Search" type="text"
                        v-model="query" />
                    <button class="search-clear" v-if="query" @click="onClearQuery"><IconX /></button>
                </SearchBar>
            </div>
            <TableSet :headings="['Entry', 'Name']">
                <tr v-for="sv in subvoce" :key="sv.id">
                    <td>
                        <router-link :to="{name:'record', params:{slug: sv.record.slug}}">
                            {{ sv.record.identifier != 0 ? sv.record.identifier : '&ndash;' }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="{name:'record', params:{slug: sv.record.slug}}">
                            {{ sv.name }}
                        </router-link>
                    </td>
                </tr>
            </TableSet>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

import Loading from "@/components/Loading"
import SearchBar from "@/components/SearchBar"
import TableSet from "@/components/TableSet"
import IconX from "@/assets/svg/icon-x.svg"

export default {
    name: 'SubVoce',
    components: {
        IconX,
        Loading,
        SearchBar,
        TableSet
    },
    computed: {
        ...mapGetters({
            'loading': 'subvoce/loading',
            'subvoce': 'subvoce/subvoce'
        })
    },
    data: function() {
        return {
            query: null
        }
    },
    head: {
        title: {
            inner: 'Sub Voce'
        },
        meta: [
            
            {name: 'description', content: '', id: 'seo-desc'},
            {name: 'og:description', content: '', id: 'og-desc'},
            {name: 'og:title', content: 'Sub Voce', id: 'og-title'}
        ]
    },
    methods: {
        onClearQuery() {
            this.query = ''
        },
        onSearchChange() {
            this.$store.dispatch('subvoce/search', this.query)
        }
    },
    mounted() {
        this.$store.dispatch('subvoce/fetch', this.$route.params.slug)
    },
    watch: {
        query() {
            this.onSearchChange()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
</style>
