<template>
<div v-html="block.value.content"></div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
    name: 'WysiwygBlock',
    components: {
        LoadingIndicator
    },
    props: {
        block: Object
    }
}
</script>

<style lang="scss" scoped>
</style>
