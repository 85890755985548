/**
 * stateHash: Is a convenience method registered globally to provide a simple
 * means of modifying the maps stateHash. This defines the state of the
 * different on-screen elements (record pane, selected map, selected filters).
 */
export default {
    install (Vue, options) {
        Vue.prototype.$stateHash = {
            clear: (key) => {
                let destination = []
                let parts = window.location.hash.split("/")
                parts.shift()
                parts.forEach(el => {
                    if(el.split(":")[0] != key) {
                        destination.push(el)
                    }
                })
                return `#/${destination.join("/")}`
            },
            get: (key) => {
                let select = null
                let parts = window.location.hash.split("/")
                parts.shift()
                parts.forEach(el => {
                    const kv = el.split(":")
                    if(kv.length > 1) {
                        if(kv[0] === key) {
                            select = kv[1]
                        }
                    }
                })
                return select
            },
            set: (key, value) => {
                let destination = []
                let parts = window.location.hash.split("/")
                parts.shift()
                parts.forEach(el => {
                    if(el.split(":")[0] != key) {
                        destination.push(el)
                    }
                })
                destination.push(`${key}:${value}`)
                return `#/${destination.join("/")}`
            }
        }
    }
}