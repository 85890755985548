<template>
<div class="credits">
    <Loading v-if="loading || !credits" />
    <div v-else>
        <div>
            <div class="wrapper-title border-bottom">
                <h1>{{ credits.title }}</h1>
            </div>
            <div class="wrapper-content">
                <StreamField :streamfield="credits.body" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'

import Loading from "@/components/Loading"
import StreamField from "@/components/StreamField"

export default {
    name: 'Credits',
    components: {
        Loading,
        StreamField
    },
    data: function() {
        return {
            credits: null,
            loading: false
        }
    },
    head: {
        title: {
            inner: 'Credits'
        },
        meta: [
            {name: 'description', content: '', id: 'seo-desc'},
            {name: 'og:description', content: '', id: 'og-desc'},
            {name: 'og:title', content: 'Credits', id: 'og-title'}
        ]
    },
    mounted() {
        this.loading = true
        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/pages/?slug=credits&type=generic.TextPage&fields=body`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                this.credits = req.response.items[0]
            } else{
                console.log(`Search error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            console.log(`Credits request failed.`)
        }
        this.loading = false
    }
}
</script>
