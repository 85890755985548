<template>
<div>
    <Loading v-if="loading" />
    <div v-else>
        <div v-if="article">
            <div class="wrapper-title border-bottom">
                <h2>{{ article.title }}</h2>
                <h3>
                    <span v-for="(author, index) in article.authors" :key="author.id">
                        <router-link :to="{name:'author', hash: '#' + format_url(author)}">{{ author.name }}</router-link>
                        <span v-if="index < article.authors.length - 1">,</span>
                    </span>
                </h3>
            </div>
            <div class="wrapper-content">
                <StreamField :streamfield="article.body" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

import Loading from "@/components/Loading"
import StreamField from "@/components/StreamField"

export default {
    name: 'Article',
    components: {
        Loading,
        StreamField
    },
    data: function() {
        return {
            route: null,
            seo_title: '',
            seo_desc: '',
            seo_img: ''
        }
    },
    computed: {
        ...mapGetters({
            'loading': 'articles/articles_loading',
            'articles': 'articles/articles',
            'article': 'articles/article',
        })
    },
    head: {
        title: function () {
           return {inner: this.seo_title}
        },
        meta: function () {
            return [
                {id: 'seo-desc', name: 'description', content: this.seo_desc},
                {id: 'og-desc', content: this.seo_desc},
                {id: 'og-title', content: this.seo_title},
                {id: 'og-image', content: this.seo_img}
            ]
        }
    },
    methods: {
        format_url(author) {
            return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi,'').replace(/\s/g, '-');
        },
        load(slug) {
            this.$store.dispatch('articles/fetch', this.$route.params.slug)
        },
    },
    mounted () {
        if (this.articles) {
            this.load(this.$route.params.slug)
        }
    },
    watch: {
        articles: function () {
            this.load(this.$route.params.slug)
        },
        article: function() {
            function isText(field) { 
                return field.type === 'wysiwyg';
            }
            let text = this.article.body.find(isText).value.content;
            text = new DOMParser().parseFromString(text, 'text/html').querySelector('p').textContent;
            text = text.substring(0, 300);
            text += '...';
            this.seo_title = this.article.title;
            this.seo_desc = text;
            this.seo_img = window.location.origin + this.article.preview_thumbnail_image.url;
            this.$emit('updateHead');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
</style>
