<template>
<div class="intro" :class="{'displayed': displayed}">
    <Loading v-if="loading || !introduction" />
    <div v-else>
        <div>
            <a class="btn-circle panel-action" @click="close()">
                <span class="sr-only">close</span>
                <IconClose/>
            </a>
            <div class="wrapper-title">
                <h1>{{ introduction.title }}</h1>
                <h2>
                    <span v-for="(author, index) in introduction.authors" :key="author.id">
                        <router-link :to="{name:'author', hash: '#' + format_url(author)}">{{ author.name }}</router-link>
                        <span v-if="index < introduction.authors.length - 1">,</span>
                    </span>
                </h2>
            </div>
            <div class="wrapper-content">
                <StreamField :streamfield="introduction.body" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import Loading from "@/components/Loading"
import StreamField from "@/components/StreamField"
import IconClose from "@/assets/svg/icon-x.svg";

export default {
    name: 'Intro',
    components: {
        Loading,
        StreamField,
        IconClose
    },
    data() {
        return {
            loading: false,
            introduction: null,
            displayed: false
        }
    },
    methods: {
        format_url(author) {
            return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi,'').replace(/\s/g, '-');
        },
        close () {
            this.$store.dispatch('global/dismissIntro', true)
        },
        fadeIn () {
            this.displayed = true;
        }
    },
    mounted() {
        setTimeout(() => {
            this.fadeIn();
        }, 2500);
    },
    created () {
        this.loading = true
        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/pages/?slug=introduction&type=generic.TextPage&fields=body,authors(*)`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                this.introduction = req.response.items[0]
            } else{
                console.log(`Search error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            console.log(`introduction request failed.`)
        }
        this.loading = false;
    },
    watch: {
        '$route.name': function(hash) {
            this.close()
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
.intro {
    position: fixed;
    top: 10%;
    max-width: 50%;
    width: 500px;
    right: 3%;
    // background: $color-blue;
    // color: $color-off-white;
    @include texture-panel-bg();
    @include dropshadow(0.2);

    opacity: 0;
    transition: opacity 250ms ease-in-out;

    &.displayed {
        opacity: 1;
    }


    .btn-circle {
        margin: 1rem 0 0 -1.2rem;
    }

    .wrapper-title {
        padding-top: 0;
        padding-bottom: 0;

        h1 {
            padding-bottom: .45rem;
        }
    }

    .wrapper-content {
        padding-top: .3rem;
    }

    a {
        // color: $color-off-white;
        font-size: 1.125rem;

    }

    @media only screen and (max-width: $mq-max-width-mobile) {
        max-width: 70%;
    }
}
</style>