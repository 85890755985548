<template>
<div>
    <div class="wrapper-title border-bottom">
        <h1>Authors</h1>
    </div>
    <div class="wrapper-content">
        <div v-for="author in authors" :key="author.slug" class="wrapper-item">
            <h1 :id="format_url(author)">{{ author.name }}</h1>
            <h2 v-if="format_title(author)">{{ format_title(author) }}</h2>
            <p>{{ author.description }}</p>
        </div>
    </div>
</div>
</template>


<script>
import { mapGetters } from "vuex"

export default {
    name: 'Author',
    computed: {
        ...mapGetters({
            'authors': 'authors/authors',
            'error': 'authors/error',
            'loading': 'authors/loading'
        })
    },
    methods: {
        format_title(author) {
            if(author.job_title && author.works_for) {
                return `${author.job_title}, ${author.works_for.name}`
            } else if (author.job_title) {
                return author.job_title
            } else if (author.works_for) {
                return author.works_for.name
            }
            return null
        },
        format_url(author) {
            return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi,'').replace(/\s/g, '-');
        },
        load(slug) {
            this.$store.dispatch('authors/fetch')
        }
    },
    head: {
        title: {
            inner: 'Authors'
        },
        meta: [
            {name: 'description', content: '', id: 'seo-desc'},
            {name: 'og:description', content: '', id: 'og-desc'},
            {name: 'og:title', content: 'Authors', id: 'og-title'}
        ]
    },
    mounted() {
        this.load(this.$route.params.slug)
        if (this.$route.hash) {
            let sidePanel = document.querySelector('.sidepanel');
            setTimeout(() => {
                sidePanel.scrollTop = document.querySelector(this.$route.hash).offsetTop;
            }, 250)
        }
    },
    updated() {
        if (this.$route.hash) {
            let sidePanel = document.querySelector('.sidepanel');
            setTimeout(() => {
                sidePanel.scrollTop = document.querySelector(this.$route.hash).offsetTop;
            }, 400)
        }
    },
    watch: {
        '$route.params.slug': function() {
            this.load(this.$route.params.slug)
        }
    }
}
</script>

<style lang="scss" scoped>

.wrapper-item {
    padding: 0;

    h1 {
        padding-top: 3rem;
    }

    p {
        margin-bottom: 0;
    }
}
</style>
