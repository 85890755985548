<template>
<header class="header">
    <div class="wrapper">
        <div class="logos">
        <router-link to="/" class="logo"><Logo class="logo" /></router-link>
        <a v-if="logoLink && logoImgUrl" :href="logoLink" class="az-logo" target="blank">
            <img :src="logoImgUrl"/>
        </a>
        </div>
        <ul class="links" :class="{'open': navOpen}">
            <li><router-link :to="{name:'records'}">Directory</router-link></li>
            <li><router-link :to="{name:'articles'}">Articles</router-link></li>
            <li><router-link :to="{name:'author'}">Authors</router-link></li>
            <li><router-link :to="{name:'credits'}">Credits</router-link></li>
        </ul>
        <button class="btn btn-hamburger" @click="onToggleNav">=</button>
    </div>
</header>
</template>

<script>
import Logo from "@/assets/svg/logo.svg";
import AzLogo from "@/assets/svg/uaz-logo.svg";

export default {
    name: 'Header',
    components: {
        Logo,
        AzLogo
    },
    data() {
        return {
            navOpen: false,
            logoLink: '',
            logoImgUrl: '',
        }
    },
    methods: {
        onCloseNav() {
            this.navOpen = false
        },
        onToggleNav() {
            this.navOpen = !this.navOpen
        }
    },
    watch: {
        '$route': function() {
            this.onCloseNav()
        }
    },
    mounted () {
        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/dag/flat-menu/`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                let link = req.response.find(i => i.handle == 'dar-logo');
                this.logoLink = link.link_page ? link.link_page.path : link.link_url;
                this.logoImgUrl = link.image ? link.image.original : '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

.header {
    background: $gradient-default-bg;
    background: $gradient-default;
    color: $color-white;
    display: block;
    height: $height-header;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    @include dropshadow(0.3);


    .logos {
        display: inline-flex;
    }

    a {
        color: $color-white;
    }

    .btn-hamburger {
        display: none;
        float: right;
    }

    .links {
        display: block;
        float: right;
        font-size: 0.9rem;
        font-variant: small-caps;
        letter-spacing: 0.1rem;
        text-transform: lowercase;
        margin: 0;
        padding: 0;

        a {
            color: $color-off-white;
        }

        li {
            list-style: none;
            float: left;
            padding: 0 1rem;
        }
    }

    .logo {
        fill: white;
        float: left;
        width: 200px;
        height: 22px;

        path {
            box-shadow: $glow-header;
        }
    }
    .az-logo {
        padding-left: 20px;
        padding-bottom: 5px;
        position: relative;
        height: 35px;
        bottom: 6px;

        img {
            height: 100%;
        }
    }

    .wrapper {
        padding: 1.1rem;
    }
}

@media only screen and (max-width: $mq-max-width-tablet) {
    header {
        .btn-hamburger {
            display: block !important;
        }

        .links {
            display: none !important;
            float: none;
            left: 0;
            position: fixed;
            top: $height-header;
            width: 100%;
            z-index: 999;

            box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.3);

            &.open {
                display: block !important;
            }

            li {
                border-bottom: 1px solid $color-blue-darker;
                margin: 0 ;
                padding: 0 !important;
                width: 100%;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    background: $gradient-default-bg;

                    display: block;
                    padding: 0.75rem 1rem;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: $mq-max-width-mobile) {
    .header {
        height: $mobile-height-header;

        .wrapper {
            padding-top: .8rem;
        }

        .logos {
            display: inline-flex;
            flex-direction: column;
        }

        .logo {
            width: 140px;
        }

        .az-logo {
            width: 140px;
            padding-left: 0;
        }

        .links {
            top: $mobile-height-header;
        }
    }
}
</style>
