<template>
    <blockquote>
        <p>{{ block.value.content }}</p>
        <cite>
            <p>{{ block.value.attribution }}</p>
            <p v-if="block.value.attribution_second_line">{{ block.value.attribution_second_line }}</p>
        </cite>
    </blockquote>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
    name: 'BlockquoteBlock',
    components: {
        LoadingIndicator
    },
    props: {
        block: Object
    }
}
</script>

<style lang="scss" scoped>
blockquote p {
    max-width: 80%;
}

cite p {
    margin: 0;
}
</style>
