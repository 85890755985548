import Vue from 'vue'

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations as ApmIntegrations } from '@sentry/apm';

import App from './App.vue'
import router from './router'
import store from './store'

import VueHead from 'vue-head'

import StateHash from './plugins/location'

import 'leaflet-bing-layer/leaflet-bing-layer.js'


// Enable Sentry
if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6c80968fb5dd4e8bba8c0628a1a72ac1@o25613.ingest.sentry.io/5390161',
    integrations: [
      new VueIntegration(
        {
          Vue,
          attachProps: true
        },
      ),
      new ApmIntegrations.Tracing(),
    ],
    tracesSampleRate: 0.25
  });
}


// Styles
import 'leaflet/dist/leaflet.css'
import "@/assets/styles/app.scss"


// Load Libraries
Vue.use(StateHash)

Vue.use(VueHead, {
  separator: '|',
  complement: 'Digital Augustan Rome'
});


// Setup App
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
