<template>
<div :class="['sidepanel', expandedClass, openClass]">
    <slot></slot>
    <a v-if="navigateTo !== 'home'" 
       class="btn-circle panel-action" @click="$router.go(-1)">
       <span class="sr-only">back</span>
       <IconArrowLeft />
    </a>
    <a v-else-if="this.routeCurrent == 'home'" 
       class="btn-circle panel-action" @click="close()">
       <span class="sr-only">close</span>
       <IconClose/>
    </a>
    <router-link v-else :to="{name: navigateTo}" class="btn-circle panel-action">
       <span class="sr-only">close</span>
       <IconClose/>
    </router-link>
    <!-- if route is home already, make button run function that closes sidepanel via class -->
</div>
</template>

<script>
import { mapGetters } from "vuex"

import IconArrowLeft from "@/assets/svg/icon-arrow-left.svg";
import IconClose from "@/assets/svg/icon-x.svg";

export default {
    name: 'SidePanel',
    components: {
        IconArrowLeft,
        IconClose
    },
    data: function() {
        return {
            expandedStates: [
                'article',
                'author',
                'credits'
            ],
            openStates: [
                'articles',
                'article',
                'author',
                'credits',
                'record',
                'records',
                'records_sans_locations',
                'subvoce'
            ],
            routeCurrent: null,
            routeLast: null
        }
    },
    computed: {
        ...mapGetters({
            'expanded': 'global/sidepanelExpanded',
            'open': 'global/sidepanelOpen'
        }),
        expandedClass() {
            return this.expanded ? 'expanded' : 'contracted'
        },
        openClass() {
            return this.open ? 'open' : 'closed'
        },
        navigateTo() {
            return ['article', 'record'].includes(this.routeCurrent) ? this.routeLast : 'home'
        }
    },
    methods: {
        onRouteChange() {
            // if (this.routeLast && this.openStates.includes('home')) {
            //     this.openStates = this.openStates.filter(s => s !== 'home');
            // }

            this.$store.dispatch('global/setSidepanelExpanded',
                this.expandedStates.includes(this.$route.name))
            this.$store.dispatch('global/setSidepanelOpen',
                this.openStates.includes(this.$route.name))
        },
        close() {
            this.$store.dispatch('global/setSidepanelOpen', false)
        }
    },
    created() {
        // this.openStates = this.openStates.concat(['home']);
    },
    mounted() {
        this.routeCurrent = this.$route.name
        this.onRouteChange()
    },
    watch: {
        '$route.name': function(route) {
            this.routeLast = this.routeCurrent
            this.routeCurrent = this.$route.name
            this.onRouteChange()
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/_vars.scss";

$button-size: 18px;

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.sidepanel {
    height: $height-body;
    overflow: scroll;
    position: fixed;
    top: $height-header;
    z-index: 100;
    transition: $transition-default;

    @include texture-panel-bg();
    @include dropshadow(0.2);

    a.panel-action {
        right: -($button-size * 2);
        position: fixed;
        top: 100px;
        transition: $transition-default;
    }

    // States
    &.contracted{ width: $width-panel; }
    &.closed{right: -$width-panel;}
    &.open{
        right: 0;
        a.panel-action {
            right: calc(#{$width-panel} - #{$button-size});
        }
    }
    &.expanded{
        width: $width-panel-expanded;

        a.panel-action {
            right: calc(#{$width-panel-expanded} - #{$button-size});
        }
    }
}

@media only screen and (max-width: $mq-max-width-mobile) {
    .sidepanel {
        height: 50%;
        left: 0;
        top: calc(100% + #{$button-size});

        a.panel-action {
            right: 20px;
            top: calc(100% + #{$button-size})
        }

        &.contracted{ width: $width-panel-mobile; }
        &.expanded{ width: $width-panel-mobile; }

        &.closed{right: -$width-panel-mobile;}
        &.open{
            left: 0;
            top: 50%;

            a.panel-action {
                right: 20px;
                top: calc(50% - #{$button-size})
            }
        }
    }
}
</style>
