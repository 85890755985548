<template>
<div>
    <Loading v-if="loading" />
    <div v-else>
        <div class="wrapper-title border-bottom">
            <h1>Articles</h1>
        </div>
        <div v-for="article in articles" :key="article.id" class="wrapper-content">
            <h2><router-link :to="{name:'article', params:{slug: article.meta.slug}}">{{ article.title }}</router-link></h2>
            <h3>
                <span v-for="author in article.authors" :key="author.id">
                    {{ author.name }},
                </span>
            </h3>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

import Loading from "@/components/Loading"

export default {
    name: 'Articles',
    components: {
        Loading
    },
    data: function() {
        return {
            route: null,
            seo_img: ''
        }
    },
    computed: {
        ...mapGetters({
            'loading': 'articles/articles_loading',
            'articles': 'articles/articles',
            'articles_page' : 'articles/articles_page'
        })
    },
    head: {
        title: {
            inner: 'Articles'
        },
        meta: function() {
            return [
                {name: 'og:title', content: 'Articles', id: 'og-title'},
                {id: 'og-image', content: this.seo_img}
            ]
        }
    },
    mounted() {
        this.$store.dispatch('articles/fetch_list')
        this.$store.dispatch('articles/fetch_page')
    },
    watch: {
        articles_page: function() {
            this.seo_img = window.location.origin + this.articles_page.items[0].preview_thumbnail_image.url;
            this.$emit('updateHead');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
</style>
