<template>
<div class="filterpanel" :class="{'open': open}">
    <div class="wrapper">
        <div class="heading">
            <h3 class="label">Filter by Feature Type</h3>
            <button class="btn-circle btn-close" @click="onCloseClick">X</button>
        </div>
        <ul class="features">
            <li><a @click="onFeaturesShowAll" :class="(selected == null) && selectedClass">Show all features</a></li>
            <li><a @click="onFeaturesHideAll" :class="(selected == -1) && selectedClass">Hide all features</a></li>
            <li v-for="feature in features" :key="feature.name">
                <a :class="(selected == feature.id) && selectedClass"
                        @click="onFilterClick(feature.id)">
                    {{ feature.name }}
                </a>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: 'Filters',
    computed: {
        ...mapGetters({
            'features': 'records/features',
            'error': 'records/features_error',
            'loading': 'records/features_loading',
            'expanded': 'global/sidepanelExpanded',
            'open': 'global/filtersOpen'
        }),
    },
    data() {
        return {
            selected: null,
            selectedClass: 'selected'
        }
    },
    methods: {
        filter(id) {
            this.$router.push({
                path: window.location.pathname,
                hash: this.$stateHash.set('filter', id)
            }, () => {})
            this.$store.dispatch('records/filter_features', id)
            this.$store.dispatch('global/setFiltersOpen', false)
        },
        onCloseClick() {
            this.$store.dispatch('global/setFiltersOpen', false)
        },
        onFilterClick(id) {
            this.filter(id)
        },
        onFeaturesHideAll() {
            this.filter(-1)
        },
        onFeaturesShowAll() {
            this.filter(null)
        }
    },
    mounted() {
        this.$store.dispatch('records/fetch_features')
    },
    watch: {
        '$route.hash': function(hash) {
            this.selected = this.$stateHash.get('filter')
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

$height: 290px;
$height-mobile: 565px;
$height-short-mobile: 60vh;

.filterpanel {
    background-color: #cccccc;
    bottom: -$height;
    height: $height;
    left: 0;
    position: fixed;
    z-index: 99;
    transition: $transition-default;
    width: 100%;

    &.open {
        bottom: 0;
    }

    @include dropshadow(0.2);

    .label {
        margin: 0.5% !important;
        margin-bottom: 0;
    }

    .features {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            float: left;
            margin: 0.5%;
            width: 24%;

            a {
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(103,115,126,0.15) 100%);
                box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
                border-left: 3px;
                border-left-color: transparent;
                border-left-style: solid;
                cursor: pointer;
                display: block;
                font-size: 0.75rem;
                padding: 1px 0.5rem;
                transition: $transition-default;

                &.selected {
                    border-left-color: rgba(85,102,120,1);
                }

                &:hover {
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(82,114,149,0.25) 100%);
                }
            }
        }
    }

    .heading {
        margin-bottom: 0.5rem;

        .label {
            float: left;
            width: 80%;
        }
        .btn-close {
            float: right;
            margin-bottom: 0.5rem;
        }
    }

    .wrapper {
        padding: 1rem;
    }
}

@media only screen and (max-width: $mq-max-width-mobile) {
    .filterpanel {
        bottom: -$height-mobile;
        height: $height-mobile;

        .features {
            li {
                margin: 0.5%;
                width: 99%;
            }
        }
    }
}
@media only screen and (max-width: $mq-max-width-tablet) {
    .filterpanel {
        bottom: -$height-mobile;
        height: $height-mobile;
        overflow-y: scroll;

        .features {
            width: 100%;

            li {
                margin: 0.5%;
                width: 99%;
            }
        }
    }
}

@media only screen and (max-height: $mq-max-width-mobile) {
    .filterpanel {
        bottom: -$height-short-mobile;
        height: $height-short-mobile;
    }
}

</style>